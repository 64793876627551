import { getBackendOptions, MultiBackend, NodeModel, Tree, TreeMethods } from '@minoru/react-dnd-treeview';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
// import './App.css';
import * as skillData from '../../skillData';
import { getSkillTree, ISkillTreeNodeData } from '../../skillData';
import SkillTreeComponent from '../SkillTree/SkillTreeComponent';

function NewSkillComponent() {

  const [treeData, setTreeData] = useState<NodeModel<ISkillTreeNodeData>[]>([])
  const [skillDescription, setSkillDescription] = useState('')
  const ref = useRef<TreeMethods>(null)

  const expandAll = (e: React.MouseEvent) => {
    ref.current?.openAll()
  }

  const saveNewSkill = (e: any) => {
    // const descrizioneSkill = (document.querySelector('#descrizioneSkillNewInput') as HTMLInputElement)?.value
    // const skillParentId = (document.querySelector('#parentSkillNewInput') as HTMLInputElement)?.value

    const selectedNodes = Array.from(document.querySelectorAll('#newSkillTree input.skillNode:checked'))
    const selectedNodesIds = selectedNodes.map(node => Number((node as HTMLElement).dataset.id) || 0)

    const payload = {
      description: skillDescription,
      parentIds: selectedNodesIds
    }
    skillData.saveNewSkill(payload)
    .then(resp => {
      alert("Salvataggio avvenuto")
      setSkillDescription('')
      reloadAndSetTreeData()
      document.querySelectorAll<HTMLInputElement>('#newSkillTree input.skillNode:checked').forEach(checkbox => checkbox.checked = false)
    })
  }


  const reloadAndSetTreeData = () => {
    getSkillTree()
    .then(data => {
      setTreeData(data)
    })
    .catch(ex => {
      console.log("Error loading tree data: ", ex)
      alert("Errore nel caricamento albero skills")
    })
  }

  useEffect(() => {
    reloadAndSetTreeData()
  }, [])

  return (
	<div className="newSkillWrapper">
    <h2>Stai inserendo una nuova skill</h2>
	<ul>
		<li>
			<label htmlFor="skillDescription">Descrizione</label>
			<input type="text" id="descrizioneSkillNewInput" name="descrizioneSkillNewInput" value={skillDescription} onChange={e => setSkillDescription(e.target.value)}/>
		</li>
		<li>
			<label htmlFor="skillDescription">Categoria</label>
      <div className="expansionStateWrapper">
        <button id="newSkillExpandAll" title="Espandi tutti" onClick={expandAll}>Espandi tutti</button>
      </div>
			<div id="newSkillTree" className="treeContainer">
				<SkillTreeComponent 
          treeData={treeData}
          showCheckBoxes={true}
          // ref={ref}
        />
        {treeData.length == 0 && <span className="message">Nessuna skill disponibile</span>}
			</div>
		</li>
		<li>
			<button id="saveNewSkill" onClick={(e) => saveNewSkill(e)}>Salva skill</button>
		</li>
	</ul>
	</div>
  );
}

export default NewSkillComponent;
