import { NodeModel } from '@minoru/react-dnd-treeview'
import { resolve } from 'path'
import config from './config.json'

export interface ISaveNewSkillOptions {
	description: string,
	parentIds: number[]
}

export interface IUpdateSkillOptions {
	idSkillTree: string | number,
	idSkill: number,
	description: string,
	parentIds: (string | number)[]	
}

export interface ISkillTreeFilters {
	description?: string
	level?: number
}

export interface ITreeElementWs {
	id_skill: number
	id_skill_tree_parent: number
	level: number
	description: string
	id_skill_tree: number
	path: string
}

// export interface ITreeElement {
// 	id: number
// 	parent: number
// 	text: string
// 	droppable?: boolean
// 	data?: {
// 		id_skill: number
// 		path: number[]
// 		level:number
// 		hasChildren: boolean
// 	}
// }

export interface ISkillTreeNodeData {
	id_skill: number
	path: number[]
	level:number
	hasChildren: boolean
}


export const hasNodeChildren = (parentNodeId: number, tree: ITreeElementWs[]) => {
	return tree.find(node => {
		return node.id_skill_tree_parent == parentNodeId
	})
}

export const getSkillParentNodeIds = (skillId: number, tree: NodeModel<ISkillTreeNodeData>[]) => {
	return tree.filter(node => {
		return node.parent && node.data?.id_skill == skillId
	}).map(foundNode => foundNode.parent)
}

export const getNodeBySkillTreeId = (skillTreeId: string | number, tree: NodeModel<ISkillTreeNodeData>[]) => {
	return tree.find(node => node.id == skillTreeId)
}

export const isSkillTreeNodeDescendantOfSkillId = (skillTreeNode: NodeModel<ISkillTreeNodeData>, skillId: number, tree: NodeModel<ISkillTreeNodeData>[]) => {
	if (!skillTreeNode.data?.path) {
		return false
	}
	return skillTreeNode.data?.path.some(parentSkillTreeId => (getNodeBySkillTreeId(parentSkillTreeId, tree)?.data?.id_skill == skillId))
}

export const getSkillTree = (options?: ISkillTreeFilters) => {
	const promise = new Promise<NodeModel<ISkillTreeNodeData>[]>((resolve, reject) => {

		fetch(config.apiUrl + config.apiSkillTreePath, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then(response => {
			return response.text()
		})
		.then(text => {			
			console.log("skills tree:", text)
			return JSON.parse(text)
		})
		.then(data => {
			// parse flat data from ws and convert to tree data
			const result = data.map((row: ITreeElementWs) => {
				return {
					id: row.id_skill_tree,
					parent: row.id_skill_tree_parent || 0,
					text: row.description,
					data: {
						id_skill: row.id_skill,
						path: row.path && row.path.split("/").slice(0,-1) as unknown as number[],  // skip the first element of the split because it's empty
						level: row.level,
						hasChildren: !!hasNodeChildren(row.id_skill_tree, data)
					}
				} as NodeModel<ISkillTreeNodeData>
			})
			resolve(result)
		})
		.catch(error => {
				console.error('Error:', error);
				reject(error)
		});
	})

	return promise
}

export const saveNewSkill = (options: ISaveNewSkillOptions) => {
	const promise = new Promise((resolve, reject) => {
		fetch(config.apiUrl + config.apiSkillPath, {
			method: 'POST',
			headers: {
			'Content-Type': 'application/json'
			},
			body: JSON.stringify(options)
		})
		.then(response => response.json())
		.then(data => {
			// do something with the response data
			resolve(data)
		})
		.catch(error => {
			console.error('Error:', error);
			reject(error)
		});
	})

	return promise
}

export const updateSkillTree = (options: IUpdateSkillOptions) => {
	const promise = new Promise((resolve, reject) => {
		fetch(config.apiUrl + config.apiSkillTreePath, {
			method: 'PATCH',
			headers: {
			'Content-Type': 'application/json'
			},
			body: JSON.stringify(options)
		})
		.then(response => response.json())
		.then(data => {
			// do something with the response data
			resolve(data)
		})
		.catch(error => {
			console.error('Error:', error);
			reject(error)
		});
	})

	return promise
}

export const deleteSkillTree = (idSkillTree: string | number) => {
	const options = {id: idSkillTree}
	const promise = new Promise((resolve, reject) => {
		fetch(config.apiUrl + config.apiSkillTreePath, {
			method: 'DELETE',
			headers: {
			'Content-Type': 'application/json'
			},
			body: JSON.stringify(options)
		})
		.then(response => response.text())
		.then(data => {
			// do something with the response data
			resolve(data)
		})
		.catch(error => {
			console.error('Error:', error);
			reject(error)
		});
	})

	return promise
}