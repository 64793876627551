import React from 'react';
// import '../../App.css';

export interface IToolbarButton {
  id: string
  text: string
  icon?: string
  disabled?: boolean
  hidden?: boolean
}

export interface IToolbarProps {
  buttons: IToolbarButton[]
  onClick: Function
}

function Toolbar(props: IToolbarProps) {
  return (
    <div id="toolbarWrapper">
      {
        props.buttons.map((button, index) => {
          return (
            <button key={"tb" + index} className="toolbarButton" id={button.id} title={button.text} disabled={button.disabled} onClick={() => props.onClick(button.id)}>{button.text}</button>
          )
        })
      }
    </div>
  );
}

export default Toolbar;