import { getBackendOptions, MultiBackend, NodeModel, Tree } from '@minoru/react-dnd-treeview';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import '../../App.scss';
import './ModSkillComponent.scss'
import * as skillData from '../../skillData';
import { getSkillTree, ISkillTreeNodeData, isSkillTreeNodeDescendantOfSkillId} from '../../skillData';
import SkillTreeComponent from '../SkillTree/SkillTreeComponent';
import { IoTrashOutline } from 'react-icons/io5';
import { resolve } from 'path';

function ModSkillComponent() {

  const [mainTreeData, setMainTreeData] = useState([] as NodeModel<ISkillTreeNodeData>[])
  const [detailTreeData, setDetailTreeData] = useState([] as NodeModel<ISkillTreeNodeData>[])
  const [selectedSkill, setSelectedSkill] = useState(null as unknown as NodeModel<ISkillTreeNodeData>)
  const [selectedSkillText, setSelectedSkillText] = useState('')
  const [checkedNodesIds, setCheckedNodesIds] = useState([] as (string | number)[])

  //#region CRUD operations

  const updateSkill = (e: any) => {
    const descrizioneSkill = (document.querySelector('#descrizioneSkillNewInput') as HTMLInputElement)?.value
    // const skillParentId = (document.querySelector('#parentSkillNewInput') as HTMLInputElement)?.value

    // const selectedNodes = Array.from(document.querySelectorAll('input.skillNode:checked'))
    // const selectedNodesIds = selectedNodes.map(node => Number((node as HTMLElement).dataset.id) || 0)
    const enabledCheckedNodesIds = checkedNodesIds.filter(checkedNodeId => {
      return isDetailCheckBoxEnabled(skillData.getNodeBySkillTreeId(checkedNodeId, mainTreeData)!)
    })

    const payload = {
      idSkillTree: selectedSkill.id,
      idSkill: selectedSkill.data!.id_skill,
      description: descrizioneSkill,
      // parentIds: selectedNodesIds
      parentIds: enabledCheckedNodesIds
    }
    skillData.updateSkillTree(payload)
    .then(resp => {
      alert("Salvataggio avvenuto")
      reloadAndSetTreeData()
      .then(res => {
        //const selectedSkillParentIds = skillData.getSkillParentNodeIds(selectedSkill.data!.id_skill, mainTreeData)
        const selectedSkillParentIds = skillData.getSkillParentNodeIds(selectedSkill.data!.id_skill, res as unknown as NodeModel<ISkillTreeNodeData>[])
        setCheckedNodesIds(selectedSkillParentIds)
      })
    })
    .catch(ex => {
      console.log("Error updating skill tree node: ", ex)
      alert("Errore nel salvataggio")
    })
  }

  //#endregion CRUD operations

  //#region main tree event handlers

  const onMainSkillTreeClicked = (node: NodeModel<ISkillTreeNodeData>) => {
    setSelectedSkill(node)
    setSelectedSkillText(node.text)
    //find parent nodes of selected skill
    const selectedSkillParentIds = skillData.getSkillParentNodeIds(node.data!.id_skill, mainTreeData)
    setCheckedNodesIds(selectedSkillParentIds)
  }

  //#endregion main tree event handlers

  //#region detail tree event handlers

  const onDetailSkillTreeCheckboxChanged = (node: NodeModel<ISkillTreeNodeData>, e: ChangeEvent<HTMLInputElement>) => {
    // update checked status of tree nodes according to event checked status
    console.log("change event: ", e)
    if (e.target.checked && e.target.dataset.id) {
      setCheckedNodesIds([...checkedNodesIds, e.target.dataset.id])
    }
    else if (!e.target.checked && e.target.dataset.id) {
      setCheckedNodesIds(checkedNodesIds.filter(checkedNodeId => checkedNodeId != e.target.dataset.id))
    }
    // const selectedSkillParentIds = skillData.getSkillParentNodeIds(node.data!.id_skill, mainTreeData)
    // setCheckedNodesIds(selectedSkillParentIds)
  }

  const onDetailDeleteClick = (node: NodeModel<ISkillTreeNodeData>, e: React.MouseEvent<HTMLSpanElement>) => {
    let canDelete = true

    if (node.data?.hasChildren) {
      canDelete = window.confirm("Questo nodo contiene altri nodi. Eliminandolo verranno eliminati tutti i discendenti. L'operazione non potrà essere annullata. Continuare comunque?")
    }

    if (canDelete) {
      skillData.deleteSkillTree(node.id) // id is id_skill_tree, not id_skill.
      .then((result: any) => {
        alert("Nodo eliminato")
        reloadAndSetTreeData()
      })
      .catch((ex: any) => {
        console.log("Error deleting tree skill: ", ex)
        alert('Si è verificato un errore')
      })
    }
  }

  //#endregion detail tree event handlers

  const isDetailCheckBoxEnabled = (node: NodeModel<ISkillTreeNodeData>) => {
  	return selectedSkill &&
    (node.data?.id_skill != selectedSkill.data?.id_skill) && 
    !isSkillTreeNodeDescendantOfSkillId(node, selectedSkill.data!.id_skill, mainTreeData) /*&&
    node.id != selectedSkill.parent*/
  }

  const getDetailDeleteIcon = (node: NodeModel<ISkillTreeNodeData>) => {
    return <IoTrashOutline/>
  }

  const reloadAndSetTreeData = () => {
    const promise = new Promise((resolve, reject) => {
      skillData.getSkillTree()
      .then(data => {
        setMainTreeData(data)
        resolve(data)
      })
      .catch(ex => {
        console.log("Error loading tree data: ", ex)
        alert("Errore nel caricamento albero skills")
        reject(ex)
      })
    })
    return promise
  }

  useEffect(() => {
    reloadAndSetTreeData()
  }, [])

  return (
	<div id="modSkillWrapper">
		<div id="modSkillMainTreeWrapper">
      <div id="modSkillContent">
        <h2>Scegli una skill da modificare</h2>
        <SkillTreeComponent treeData={mainTreeData} onClick={(node) => onMainSkillTreeClicked(node)} />
      </div>
		</div>
		<div id="detailSkillWrapper">
		<ul className="formWrapper">
			<li className="treeListItem">
        {/* <div className="treeListItem"> */}
          <label htmlFor="skillDescription">Descrizione</label>
          <div className="rightColumn">
            <input type="text" id="descrizioneSkillNewInput" name="descrizioneSkillNewInput" value={selectedSkillText} onChange={(e) => setSelectedSkillText(e.target.value)}/>
            <button id="updateSkill" onClick={(e) => updateSkill(e)}>Salva modifiche skill</button>
          </div>
        {/* </div> */}
			</li>
			<li className="treeListItem withTitle">
        <h3 /*htmlFor="detailSkillTree"*/>Scegli una o più categorie</h3>
        <div id="detailSkillTree" className="rightColumn">
          <SkillTreeComponent
            treeData={mainTreeData}
            showCheckBoxes={true}
            getDeleteIcon={getDetailDeleteIcon}  
            checkedNodesIds={checkedNodesIds}
            isCheckBoxEnabled={isDetailCheckBoxEnabled}
            onCheckBoxChange={onDetailSkillTreeCheckboxChanged}
            onDeleteClick={onDetailDeleteClick}
          />
        </div>
			</li>
			<li className="actionButton">
				<button id="updateSkill" onClick={(e) => updateSkill(e)}>Salva modifiche skill</button>
			</li>
		</ul>
		</div>
	</div>
  );
}

export default ModSkillComponent;
