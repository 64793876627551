import { getBackendOptions, MultiBackend, NodeModel, Tree, TreeMethods } from '@minoru/react-dnd-treeview';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
// import { ITreeElement } from '../../skillData';
import './SkillTreeComponent.scss'
import { IoCaretForward, IoCaretDown } from 'react-icons/io5'
import { ISkillTreeNodeData } from '../../skillData';

export interface ISkillTreeProps {
	treeData: NodeModel<ISkillTreeNodeData>[]
	showCheckBoxes?: boolean
	checkedNodesIds?: (string | number)[]
	getDeleteIcon?: (node: NodeModel<ISkillTreeNodeData>) => JSX.Element
	onClick?: (node: NodeModel<ISkillTreeNodeData>) => void
	isCheckBoxEnabled?: (node: NodeModel<ISkillTreeNodeData>) => boolean
	onCheckBoxChange?: (node: NodeModel<ISkillTreeNodeData>, e: ChangeEvent<HTMLInputElement>) => void
	onDeleteClick?: (node: NodeModel<ISkillTreeNodeData>, e: React.MouseEvent<HTMLSpanElement>) => void
	// ref: React.ForwardedRef<TreeMethods>
}


// export type TreeRef = React.ForwardedRef<TreeMethods> & { forwardRef: React.ForwardedRef<any> };
// export type TreeRef = React.ForwardedRef<TreeMethods> & React.RefAttributes<HTMLElement>;

// type TreeRef = {
	// 	current: TreeMethods | null;
	//   } & React.RefAttributes<HTMLElement>;
	
const SkillTreeComponent = (props: ISkillTreeProps) => {
// const SkillTreeComponent = React.forwardRef<TreeMethods, ISkillTreeProps>((props: ISkillTreeProps, ref) => {
		
	const myref = useRef<TreeMethods>(null)

	const expandAll = () => {
		myref.current?.openAll()
	}

	const collapseAll = () => {
		myref.current?.closeAll()
	}
	// const treeRef = useRef<TreeMethods>(null);
	
	return (
	<div className="skillTreeComponentWrapper">
		<div className="expansionStateWrapper">
			<button id="newSkillCollapseAll" title="Collassa tutti" onClick={(e) => collapseAll()}>Collassa tutti</button>
			<button id="newSkillExpandAll" title="Espandi tutti" onClick={(e) => expandAll()}>Espandi tutti</button>
		</div>
		<DndProvider backend={MultiBackend} options={getBackendOptions()}>
			<Tree
			ref={myref}
			tree={props.treeData}
			rootId={0}
			onDrop={() => {}}
			render={(node, { depth, isOpen, onToggle }) => (
				<div style={{ marginLeft: depth * 10 }}>
					{node.data?.hasChildren && (
						<span onClick={onToggle} className="toggleSymbol">{isOpen ? <IoCaretDown/> : <IoCaretForward/>}</span>
					)}
					{props.showCheckBoxes && <input type="checkbox" className="skillNode" name={"node"+node.id} id={"node"+node.id} data-id={node.id} checked={props.checkedNodesIds && props.checkedNodesIds.find(checkedNodeId => checkedNodeId == node.id) !== undefined} disabled={props.isCheckBoxEnabled && !props.isCheckBoxEnabled(node as NodeModel<ISkillTreeNodeData>)} onChange={e => props.onCheckBoxChange && props.onCheckBoxChange(node as NodeModel<ISkillTreeNodeData>, e)}></input>}
					{props.getDeleteIcon && (<span id={"deleteNode"+node.id} data-id={node.id} title="Elimina" onClick={e => props.onDeleteClick && props.onDeleteClick(node as NodeModel<ISkillTreeNodeData>, e)}>{props.getDeleteIcon(node as NodeModel<ISkillTreeNodeData>)}</span>)}
					<span className="nodeText" onClick={(e) => props.onClick && props.onClick(node as NodeModel<ISkillTreeNodeData>)}>{`${node.text} (idsk: ${node.data?.id_skill}, idskt: ${node.id})`}</span>
				</div>
			)}  
			/>
		</DndProvider>
	</div>
	)
}

export default SkillTreeComponent