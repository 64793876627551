import React, { useEffect, useState } from 'react';
import './App.scss';
import ModSkillComponent from './components/modSkillComponent/ModSkillComponent';
import NewSkillComponent from './components/newSkillComponent/NewSkillComponent';
import Toolbar from './components/toolbar/Toolbar';
import config from './config.json'

function App() {

  const [activeTab, setActiveTab] = useState('new')
  // const [treeData, setTreeData] = useState([] as ITreeElement[])

  const toolbarNewClicked = (buttonId: string) => {
    switch (buttonId) {
      case 'new':
        setTimeout(() => setActiveTab('new'), 10)
      break;
      case 'mod':
        setTimeout(() => setActiveTab('mod'), 10)
      break;
      case 'excel':
        const link = document.createElement('a');
        link.href = config.apiUrl + '/exceltree';
        link.dispatchEvent(new MouseEvent('click'));
      break;
    }
  }

  return (
    <div className="App">
      <div id="treePreview"></div>
      <div id="actionPanel">
        <Toolbar buttons={[
          {id: "new", text: "Inserimento nuova skill"},
          {id: "mod", text: "Modifica skill esistente"},
          {id: "excel", text: "Scarica in formato Excel"}
        ]} onClick={toolbarNewClicked}></Toolbar>

        {activeTab == 'new' && <NewSkillComponent/> }
        {activeTab == 'mod' && <ModSkillComponent/> }
      </div>
    </div>
  );
}

export default App;
